import React from "react"
import styled from "styled-components"

import logo from "../assets/ofest.png"

const Table = () => {
  return (
    <TableSection>
      <div style={{ background: "white" }}>
        <img style={{ width: "100%" }} src={logo} alt="" />
      </div>

      <Content>
        <h3>Where are you sitting?</h3>
        <BtnContainer>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://res.cloudinary.com/gonation/image/upload/v1600464517/sites/dockside-brewing/upper.jpg"
          >
            Upper Deck
          </a>
        </BtnContainer>
        <BtnContainer>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://res.cloudinary.com/gonation/image/upload/v1600464519/sites/dockside-brewing/lower.jpg"
          >
            Lower Deck
          </a>
        </BtnContainer>
        <BtnContainer>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://res.cloudinary.com/gonation/image/upload/v1600464514/sites/dockside-brewing/bier.jpg"
          >
            Biergarten
          </a>
        </BtnContainer>
        <BtnContainer>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://res.cloudinary.com/gonation/image/upload/v1600464514/sites/dockside-brewing/grassy.jpg"
          >
            Grassy Knoll
          </a>
        </BtnContainer>
        <img
          style={{ maxWidth: "200px", margin: "auto" }}
          src="https://www.gonationsites.com/GNSE/gn-sites/images/gn-power-black.svg"
        />
      </Content>
    </TableSection>
  )
}

export default Table

const TableSection = styled.section`
  text-align: center;
  background: ${({ theme }) => theme.light};
  height: 100vh;

  h3 {
    margin-bottom: 1.5rem;
  }

  a {
    font-family: "Rubik", sans-serif;
    background: ${({ theme }) => theme.primary};
    color: white;
    padding: 0.75rem 2rem;
    border: none;
    box-shadow: none;
    width: 100%;
    border-radius: 30px;
    font-size: 1rem;
    font-weight: 500;
    display: block;
    text-decoration: none;
  }
`

const BtnContainer = styled.div`
  margin-bottom: 1.5rem;
`

const Content = styled.div`
  padding: 1.5rem;
  background: ${({ theme }) => theme.light};
`
